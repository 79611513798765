import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _706372a6 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _6aaad5de = () => interopDefault(import('../pages/auth/signin.vue' /* webpackChunkName: "pages/auth/signin" */))
const _7f412c54 = () => interopDefault(import('../pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _310d0607 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _604312ca = () => interopDefault(import('../pages/_appid/index.vue' /* webpackChunkName: "pages/_appid/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _706372a6,
    name: "404"
  }, {
    path: "/auth/signin",
    component: _6aaad5de,
    name: "auth-signin"
  }, {
    path: "/auth/signup",
    component: _7f412c54,
    name: "auth-signup"
  }, {
    path: "/",
    component: _310d0607,
    name: "index"
  }, {
    path: "/:appid",
    component: _604312ca,
    name: "appid"
  }, {
    path: "*",
    component: _706372a6,
    name: "custom"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
